import { useNavigate, useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import Error from './Error';

const Auth0ProviderWithHistory = ({ children, audience, domain, clientId }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const onRedirectCallback = appState => {
    navigate(appState?.returnTo || '/');
  };

  // Dynamically set the redirect_uri based on the current window location
  const dynamicRedirectUri = `${window.location.origin}/auth/auth0/callback`;

  // Function to parse query parameters
  const getQueryParams = query => {
    return new URLSearchParams(query);
  };

  // Extract invitation parameters from the URL
  const queryParams = getQueryParams(location.search);
  const invitation = queryParams.get('invitation');
  const organization = queryParams.get('organization');
  const organizationName = queryParams.get('organization_name');

  const error = queryParams.get('error');
  const errorDescription = queryParams.get('error_description');

  // Include invitation parameters in authorizationParams if they exist
  let authorizationParams = {
    redirect_uri: dynamicRedirectUri,
    audience: `${audience}`,
    scope:
      'openid profile email read:me read:admin_settings read:insights update:insights read:user_interactions update:user_interactions',
  };

  if (invitation && organization) {
    authorizationParams = {
      ...authorizationParams,
      invitation,
      organization,
      organization_name: organizationName,
    };
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={authorizationParams}
      onRedirectCallback={onRedirectCallback}
    >
      {!!error && <Error errorDescription={errorDescription} />}
      {!error && children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
