import React, { useEffect, useState } from 'react';
import Plot from 'react-plotly.js';
import dayjs from 'dayjs';
import { format } from 'd3-format';
import { Typography } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LightTooltip from '../LightTooltip';

export default function LimitsChart({
  granularity,
  baseline,
  anomalyDetectionMode,
  keyMetric: { time_series: timeSeries, expected_range: expectedRange, symbol: currency },
}) {
  const titleText =
    anomalyDetectionMode === 'RP'
      ? 'Anomaly detection mode: Revenue Targets'
      : 'Anomaly detection mode: Historical Trends';
  const titleTooltip =
    anomalyDetectionMode === 'RP'
      ? 'Business-defined targets from Revenue Plans were used as the baseline (±10%); the actual revenue is compared against them to detect gaps.'
      : 'Historical revenue data were analyzed to predict the min and max expected values; anomalies were identified by comparing the actual revenue against them.';
  const minSeries = timeSeries.map(({ min_expected }) => min_expected);
  const maxSeries = timeSeries.map(({ max_expected }) => max_expected);
  const metricSeries = timeSeries.map(({ metric }) => metric);
  const periods = timeSeries.map(({ period }) => period);
  const weekends = periods.filter(period => dayjs(period).day() === 0 || dayjs(period).day() === 6);
  const minValue = Math.min(
    baseline,
    expectedRange.min,
    ...minSeries.filter(value => value !== undefined),
    ...metricSeries,
  );
  const maxValue = Math.max(
    baseline,
    expectedRange.max,
    ...maxSeries.filter(value => value !== undefined),
    ...metricSeries,
  );
  const rangeMin = minValue - (maxValue - minValue) * 0.05;
  const rangeMax = maxValue + (maxValue - minValue) * 0.05;
  const currentPeriod = timeSeries[timeSeries.length - 1];

  const xBackAndForth = [...periods, ...periods.reverse()];

  const [winWidth, setWinWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setWinWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const mobile = winWidth < 600;
  const mobileXS = winWidth <= 410;

  // 410 is when the labels go from one line into to lines.
  // So we use smaller text to fit in one line
  const limitsGraphLabel = mobileXS ? 'expected' : 'min/max expected';
  const limitsGraph =
    anomalyDetectionMode === 'RP'
      ? {
          name: limitsGraphLabel,
          type: 'scatter',
          mode: 'markers',
          marker: { size: 10, color: 'rgb(194, 199, 207)' },
          cliponaxis: false,
          y: [expectedRange.min, expectedRange.max],
          x: [currentPeriod.period, currentPeriod.period],
        }
      : {
          name: limitsGraphLabel,
          type: 'scatter',
          x: xBackAndForth,
          y: [...maxSeries, ...minSeries.reverse()],
          fill: 'tozerox',
          fillcolor: '#EBF0F5',
          line: { color: '#E7E8EB', shape: 'spline', width: 2 },
          showlegend: true,
          opacity: 0.5,
        };

  const valueGraph = {
    name: 'revenue',
    type: 'scatter',
    mode: 'lines',
    lineShape: 'spline',
    x: periods,
    y: metricSeries.reverse(),
    line: { color: '#729dd6', shape: 'spline', width: 2.5 },
  };

  const baselineMark = {
    name: 'baseline',
    type: 'scatter',
    mode: 'markers',
    marker: { size: 10, color: '#2F54E7' },
    cliponaxis: false,
    y: [baseline],
    x: [currentPeriod.period],
  };

  const data = [limitsGraph, valueGraph, baselineMark];

  const first_date = new Date(periods[periods.length - 2]);
  const second_date = new Date(periods[periods.length - 1]);
  const days_between_ticks = Math.round(
    (first_date.getTime() - second_date.getTime()) / (1000 * 60 * 60 * 24),
  );
  const isMonthly = days_between_ticks > 20 && days_between_ticks < 40;
  const tickOptions = mobile
    ? {
        tickmode: 'auto',
        nticks: 10,
      }
    : {
        tickmode: isMonthly ? 'array' : 'linear',
        tick0: periods[periods.length - 1],
        dtick: isMonthly ? undefined : days_between_ticks * 24 * 60 * 60 * 1000, // in milliseconds
        tickvals: isMonthly ? periods : undefined,
      };

  const margins = mobile ? (maxValue >= 10000 ? { r: 5, l: 36 } : { r: 5, l: 30 }) : { r: 30 };
  const yformat = mobile || maxValue >= 100000000 ? format('~s') : ',.0f';
  const layout = {
    dragmode: false,
    legend: {
      orientation: 'h',
      yanchor: 'bottom',
      y: mobile ? -0.22 : -0.3,
      xanchor: 'center',
      x: 0.5,
      font: { size: mobileXS ? 11 : 12 },
    },
    margin: { b: 50, t: 30, ...margins, pad: 4 },
    paper_bgcolor: 'rgb(255,255,255)',
    plot_bgcolor: 'rgb(255,255,255)',
    xaxis: {
      gridcolor: 'rgb(235,235,235)',
      range: [periods[periods.length - 1], periods[0]],
      showgrid: true,
      showline: true,
      showticklabels: true,
      tickcolor: 'rgb(127,127,127)',
      ticks: 'outside',
      zeroline: false,
      tickangle: 0,
      tickformat: '%d \n %b',
      hoverformat: '%B-%d-%Y',
      ...tickOptions,
    },
    yaxis: {
      gridcolor: 'rgb(235,235,235)',
      showgrid: true,
      showline: false,
      showticklabels: true,
      tickcolor: 'rgb(127,127,127)',
      ticks: mobile ? 'inside' : 'outside',
      zeroline: false,
      range: [rangeMin, rangeMax],
      nticks: 10,
      tickformat: yformat,
      tickprefix: currency,
    },
    shapes:
      granularity === 'daily'
        ? weekends.map(p => ({
            type: 'line',
            x0: p,
            x1: p,
            y0: 0,
            y1: 1,
            yref: 'paper',
            line: {
              color: '#102C41',
              width: 0.5,
              dash: 'dot',
            },
          }))
        : undefined,
  };

  const config = {
    responsive: true,
    fillFrame: false,
    scrollZoom: false,
    displaylogo: false,
    displayModeBar: false,
  };

  return (
    <>
      <Typography sx={{ fontSize: '12px', marginTop: '20px' }}>
        {titleText}
        <LightTooltip title={titleTooltip} placement="bottom-start" arrow>
          <InfoOutlinedIcon
            sx={{ cursor: 'pointer', fontSize: '16px', verticalAlign: 'middle', marginLeft: '5px' }}
          />
        </LightTooltip>
      </Typography>
      <Plot
        data={data}
        layout={layout}
        config={config}
        style={{ width: '100%', height: '450px' }}
      />
    </>
  );
}
